"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
  var a,
      b,
      c,
      d,
      e,
      f,
      g,
      h,
      i,
      j,
      k,
      l,
      m,
      n,
      o,
      p,
      q,
      r,
      s = [].indexOf || function (a) {
    for (var b = 0, c = this.length; c > b; b++) {
      if (b in this && this[b] === a) return b;
    }return -1;
  };a = jQuery, d = [{ type: "maestro", pattern: /^(5018|5020|5038|6304|6759|676[1-3])/, length: [12, 13, 14, 15, 16, 17, 18, 19], cvcLength: [3], luhn: !0 }, { type: "dinersclub", pattern: /^(36|38|30[0-5])/, length: [14], cvcLength: [3], luhn: !0 }, { type: "laser", pattern: /^(6304|6706|6771|6709)/, length: [16, 17, 18, 19], cvcLength: [3], luhn: !0 }, { type: "jcb", pattern: /^35/, length: [16], cvcLength: [3], luhn: !0 }, { type: "unionpay", pattern: /^62/, length: [16, 17, 18, 19], luhn: !1 }, { type: "discover", pattern: /^(6011|65|64[4-9]|622)/, length: [16], cvcLength: [3], luhn: !0 }, { type: "mastercard", pattern: /^5[1-5]/, length: [16], cvcLength: [3], luhn: !0 }, { type: "amex", pattern: /^3[47]/, length: [15], cvcLength: [4], luhn: !0 }, { type: "visa", pattern: /^4/, length: [13, 14, 15, 16], cvcLength: [3], luhn: !0 }], b = function b(a) {
    var b, c, e;for (a = (a + "").replace(/\D/g, ""), c = 0, e = d.length; e > c; c++) {
      if (b = d[c], b.pattern.test(a)) return b;
    }
  }, c = function c(a) {
    var b, c, e;for (c = 0, e = d.length; e > c; c++) {
      if (b = d[c], b.type === a) return b;
    }
  }, r = function r(a) {
    return (a + "").replace(/^\s+|\s+$/g, "");
  }, l = function l(a) {
    var b, c, d, e, f, g;for (d = !0, e = 0, c = (a + "").split("").reverse(), f = 0, g = c.length; g > f; f++) {
      b = c[f], b = parseInt(b, 10), (d = !d) && (b *= 2), b > 9 && (b -= 9), e += b;
    }return 0 === e % 10;
  }, k = function k(a) {
    var b;return null != a.prop("selectionStart") && a.prop("selectionStart") !== a.prop("selectionEnd") ? !0 : ("undefined" != typeof document && null !== document ? null != (b = document.selection) ? "function" == typeof b.createRange ? b.createRange().text : void 0 : void 0 : void 0) ? !0 : !1;
  }, g = function g(c) {
    var d, e, f, g, h, i, j;return f = String.fromCharCode(c.which), !/^\d+$/.test(f) || (d = a(c.currentTarget), j = d.val(), e = b(j + f), g = (j.replace(/\D/g, "") + f).length, i = 16, e && (i = e.length[e.length.length - 1]), g >= i || null != d.prop("selectionStart") && d.prop("selectionStart") !== j.length) ? void 0 : (h = e && "amex" === e.type ? /^(\d{4}|\d{4}\s\d{6})$/ : /(?:^|\s)(\d{4})$/, h.test(j) ? (c.preventDefault(), d.val(j + " " + f)) : h.test(j + f) ? (c.preventDefault(), d.val(j + f + " ")) : void 0);
  }, e = function e(b) {
    var c, d;return c = a(b.currentTarget), d = c.val(), b.meta || null != c.prop("selectionStart") && c.prop("selectionStart") !== d.length ? void 0 : 8 === b.which && /\s\d?$/.test(d) ? (b.preventDefault(), c.val(d.replace(/\s\d?$/, ""))) : void 0;
  }, h = function h(b) {
    var c, d, e;return d = String.fromCharCode(b.which), /^\d+$/.test(d) ? (c = a(b.currentTarget), e = c.val() + d, /^\d$/.test(e) && "0" !== e && "1" !== e ? (b.preventDefault(), c.val("0" + e + " / ")) : /^\d\d$/.test(e) ? (b.preventDefault(), c.val("" + e + " / ")) : void 0) : void 0;
  }, i = function i(b) {
    var c, d, e;return d = String.fromCharCode(b.which), /^\d+$/.test(d) ? (c = a(b.currentTarget), e = c.val(), /^\d\d$/.test(e) ? c.val("" + e + " / ") : void 0) : void 0;
  }, j = function j(b) {
    var c, d, e;return d = String.fromCharCode(b.which), "/" === d ? (c = a(b.currentTarget), e = c.val(), /^\d$/.test(e) && "0" !== e ? c.val("0" + e + " / ") : void 0) : void 0;
  }, f = function f(b) {
    var c, d;if (!b.meta && (c = a(b.currentTarget), d = c.val(), 8 === b.which && (null == c.prop("selectionStart") || c.prop("selectionStart") === d.length))) return (/\s\/\s?$/.test(d) ? (b.preventDefault(), c.val(d.replace(/\s\/\s?$/, ""))) : void 0
    );
  }, p = function p(a) {
    var b;return a.metaKey ? !0 : 32 === a.which ? !1 : 0 === a.which ? !0 : 33 > a.which ? !0 : (b = String.fromCharCode(a.which), !!/[\d\s]/.test(b));
  }, n = function n(c) {
    var d, e, f, g;return d = a(c.currentTarget), f = String.fromCharCode(c.which), /^\d+$/.test(f) && !k(d) ? (g = (d.val() + f).replace(/\D/g, ""), e = b(g), e ? g.length <= e.length[e.length.length - 1] : 16 >= g.length) : void 0;
  }, o = function o(b) {
    var c, d, e;return c = a(b.currentTarget), d = String.fromCharCode(b.which), /^\d+$/.test(d) && !k(c) ? (e = c.val() + d, e = e.replace(/\D/g, ""), e.length > 6 ? !1 : void 0) : void 0;
  }, m = function m(b) {
    var c, d;return c = a(b.currentTarget), d = c.val(), 4 >= d.length;
  }, q = function q(b) {
    var c, e, f, g, h;return c = a(b.currentTarget), h = c.val(), g = a.cardType(h) || "unknown", c.hasClass(g) ? void 0 : (e = function () {
      var a, b, c;for (c = [], a = 0, b = d.length; b > a; a++) {
        f = d[a], c.push(f.type);
      }return c;
    }(), c.removeClass("unknown"), c.removeClass(e.join(" ")), c.addClass(g), c.toggleClass("identified", "unknown" !== g), c.trigger("payment.cardType", g));
  }, a.fn.formatCardCVC = function () {
    return this.restrictNumeric(), this.on("keypress", m);
  }, a.fn.formatCardExpiry = function () {
    return this.restrictNumeric(), this.on("keypress", o), this.on("keypress", h), this.on("keypress", j), this.on("keypress", i), this.on("keydown", f);
  }, a.fn.formatCardNumber = function () {
    return this.restrictNumeric(), this.on("keypress", n), this.on("keypress", g), this.on("keydown", e), this.on("keyup", q);
  }, a.fn.restrictNumeric = function () {
    return this.on("keypress", p);
  }, a.fn.cardExpiryVal = function () {
    return a.cardExpiryVal(a(this).val());
  }, a.cardExpiryVal = function (a) {
    var b, c, d, e;return a = a.replace(/\s/g, ""), e = a.split("/", 2), b = e[0], d = e[1], 2 === (null != d ? d.length : void 0) && /^\d+$/.test(d) && (c = new Date().getFullYear(), c = ("" + c).slice(0, 2), d = c + d), b = parseInt(b, 10), d = parseInt(d, 10), { month: b, year: d };
  }, a.validateCardNumber = function (a) {
    var c, d;return a = (a + "").replace(/\s+|-/g, ""), /^\d+$/.test(a) ? (c = b(a), c ? (d = a.length, s.call(c.length, d) >= 0 && (c.luhn === !1 || l(a))) : !1) : !1;
  }, a.validateCardExpiry = function (a, b) {
    var c, d, e;return "object" == (typeof a === "undefined" ? "undefined" : _typeof(a)) && "month" in a && (e = a, a = e.month, b = e.year), a && b ? (a = r(a), b = r(b), /^\d+$/.test(a) ? /^\d+$/.test(b) ? 12 >= parseInt(a, 10) ? (d = new Date(b, a), c = new Date(), d.setMonth(d.getMonth() - 1), d.setMonth(d.getMonth() + 1, 1), d > c) : !1 : !1 : !1) : !1;
  }, a.validateCardCVC = function (a, b) {
    var d, e;return a = r(a), /^\d+$/.test(a) ? b ? (d = a.length, s.call(null != (e = c(b)) ? e.cvcLength : void 0, d) >= 0) : a.length >= 3 && 4 >= a.length : !1;
  }, a.cardType = function (a) {
    var c;return a ? (null != (c = b(a)) ? c.type : void 0) || null : null;
  };
}).call(undefined);